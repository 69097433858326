<template>
  <div class="c-app flex-row align-items-center mainbg">
    <CContainer>
    <div class="center">
    <CCard class="col-md-4 p-4 justify-content-center">
            <center>
         <h4 style="color:red;">It's great to meet you.</h4>
                <label class="mt-3">Tell us about your self.</label>
    <form @submit.prevent="SubmitQna()">
      <div class="form-group text-left">
        <label for="name">Company Name</label>
        <CInput size="sm" type="text" id="name" v-model="qna.companyName" required />
      </div>
      <div class="form-group text-left">
        <label for="email">How many people work at your company?</label>
        <CSelect
          :options="emp_opt"
          :value.sync="qna.numOfEmployee"
          size="sm"
          placeholder="select"
          required
        />      
      </div>
      <div class="form-group text-left">
        <label for="password">What field do you work in?</label>
        <CSelect
          :options="fields_opt"
          :value.sync="qna.field"
          size="sm"
          placeholder="select"
          required
        />
      </div>
      <div class="form-group text-left">
        <label for="password">What are you looking for</label>
        <CSelect
          :options="goal_opt"
          :value.sync="qna.goal"
          size="sm"
          placeholder="select"
          required
        />
      </div>
      <CButton color="success" type="submit">Let's Go!</CButton>
    </form>
    <br>
    </center>
      </CCard>
    </div>
  </CContainer>
    </div>
</template>



<script>
import Swal from 'sweetalert2/dist/sweetalert2.js'
import 'sweetalert2/src/sweetalert2.scss'
import LoginService from './../../services/loginControlService'
export default{
    name:'VerifyAccount',
    data(){
        return{
            qna:{email:''},
            emp_opt:[{ label: "Select One", value: null },
                  { label: "2 to 10", value: '2-10' },
                  { label: "11 to 25", value: '11-25' },
                  { label: "25 to 50", value: '25-50' },
                  { label: "50 to 100", value: '50-100' },
                  { label: "100 to 200", value: '100-200' },
                  { label: "200+", value: '200+' }
          ],
            fields_opt:[{ label: "Select One", value: null },
                        { label: "Marketing", value: 'marketing' },
                        { label: "Affiliate Marketing", value: 'affiliate_marketing' },
                        { label: "Cybersecurity Fraud Protection", value: 'cybersecurity_fraud_protection' },
                        { label: "Telecommunications", value: 'telecommunications' }, ],
            goal_opt:[{ label: "Select One", value: null },
                { label: "Heatmap", value: 'heatmap' },
                { label: "Tracking", value: 'tracking' },
                { label: "Fraud Detection", value: 'fraud_detection' }]
        }
    },
    mounted(){
      this.qna.email = this.$root.$data.user_email
      console.log(this.$root.$data.user_email)
    },
    methods:{
        async SubmitQna(){
            let response = await LoginService.onboard(this.qna);
            if(response.result){
                Swal.fire({title: 'Success',text: "Thank you for submitting.",icon: 'success',confirmButtonText: 'Close',position: 'top-end',showConfirmButton: false,timer: 4000})
                this.$router.push('/')
            }
            else{
              Swal.fire({title: 'Error',text: response.message,icon: 'error',confirmButtonText: 'Close',position: 'top-end',showConfirmButton: false,timer: 4000})
            }
        },
      async logout() {
              let response = await LoginService.logout()
              window.localStorage.setItem("user", null);
              window.localStorage.setItem("status", null);
              window.localStorage.setItem("email", null);
              window.localStorage.setItem("iwitness", 'false');
              this.$router.push("/pages/login");
          },
    }
}
</script>



<style scoped>
.center {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 90vh;
}
</style>